import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar";
import { LoginContext } from "../../../Contexts/LoginContextProvider";
import { PaginationContext } from "../../../Contexts/PaginationContext";

function ProductList({ title }) {
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const productsPerPage = 7; // Number of products per page
    const navigate = useNavigate();

    const { getToken, loginState } = useContext(LoginContext);
    const { savePaginationState, getPaginationState } = useContext(PaginationContext);
    const token = getToken(); // Retrieve the token from context

    useEffect(() => {
        // Restore saved state on mount
        const savedState = getPaginationState("products");
        setSearchQuery(savedState.filter || "");
        setCurrentPage(savedState.currentPage || 1);
    }, [getPaginationState]);

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchProducts();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const fetchProducts = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(`https://v2.whustle.com/products/${loginState.store_id}`, {
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const deleteProduct = (productId) => {
        if (window.confirm("Are you sure you want to delete this product?")) {
            fetch(`https://v2.whustle.com/products/${productId}`, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            })
                .then(() =>
                    setProducts((prev) => prev.filter((product) => product.id !== productId))
                )
                .catch((error) => console.error("Error deleting product:", error));
        }
    };

    // Filter products locally based on the search query
    const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const handleFilterChange = (e) => {
        const newFilter = e.target.value;
        setSearchQuery(newFilter);
        setCurrentPage(1); // Reset to the first page when the filter changes
        savePaginationState("products", { currentPage: 1, filter: newFilter });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        savePaginationState("products", { currentPage: newPage, filter: searchQuery });
    };

    const handleNavigate = (path) => {
        savePaginationState("products", { currentPage, filter: searchQuery });
        navigate(path);
    };

    return (
        <div className="flex flex-col md:flex-row w-full bg-gray-900 text-gray-100 min-h-screen overflow-y-auto">
            {loading && (
                <div className="fixed flex justify-center items-center w-full h-screen bg-black bg-opacity-60 z-50 top-0 left-0">
                    <img
                        src="https://media.tenor.com/DYYUoqO0GJ0AAAAj/among-us-walking.gif"
                        alt="Loading"
                    />
                </div>
            )}
            <Navbar />
            <div className="p-4 md:p-8 h-screen md:w-2/3 mx-auto">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-gray-100">
                    Product List
                </h1>

                <div className="flex justify-between items-center mb-6 text-gray-100">
                    <h2 className="text-lg md:text-xl">Products</h2>
                    <button
                        onClick={() => handleNavigate("/create")}
                        className="mt-4 text-white bg-green-600 hover:bg-green-700 font-medium rounded-lg text-sm px-4 py-2"
                    >
                        Create New Product
                    </button>
                </div>

                {/* Search Input */}
                <div className="mb-6">
                    <input
                        type="text"
                        placeholder="Search by product title"
                        value={searchQuery}
                        onChange={handleFilterChange}
                        className="border border-gray-700 p-2 rounded-lg w-full bg-gray-800 text-gray-100 placeholder-gray-400"
                    />
                </div>

                <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-800">
                    <table className="w-full text-sm text-left text-gray-200">
                        <thead className="text-xs uppercase bg-gray-700 text-gray-300">
                        <tr>
                            <th className="px-6 py-3">Name</th>
                            <th className="px-6 py-3">Id</th>
                            <th className="px-6 py-3">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentProducts.map((product) => (
                            <tr
                                key={product.id}
                                className="border-b border-gray-700 hover:bg-gray-700"
                            >
                                <td className="px-6 py-4 font-medium text-gray-100">{product.title}</td>
                                <td className="px-6 py-4 font-medium text-gray-100">{product.id}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => handleNavigate(`/edit/${product.id}`)}
                                        className="text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2 mr-2"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => deleteProduct(product.id)}
                                        className="text-white bg-red-600 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {currentProducts.length === 0 && (
                            <tr>
                                <td colSpan="3" className="px-6 py-4 text-gray-300 text-center">
                                    No products found.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                {filteredProducts.length > 0 && (
                    <div className="flex justify-between items-center mt-4">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === 1
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            Previous
                        </button>
                        <span className="text-gray-300 font-medium">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === totalPages
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductList;
