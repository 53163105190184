import React, {useContext, useState} from "react";
import Navbar from "../../Components/Navbar";
import {LoginContext} from "../../Contexts/LoginContextProvider";

function UploadPage() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const {loginState, getToken} = useContext(LoginContext);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file) {
            setMessage('Please provide an image');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://v2.whustle.com/upload', {
                method: 'POST',
                headers: {
                    'Authorization': getToken(),
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message || 'Image uploaded successfully!');
            } else {
                const errorData = await response.json();
                setMessage(errorData.error || 'Failed to upload image');
            }
        } catch (error) {
            setMessage('Error uploading image: ' + error.message);
        }
    };

    return (
        <div className="bg-gray-900 min-h-screen flex flex-col md:flex-row w-full text-white">
            <div className={"md:min-h-screen bg-gray-800"}>
                <Navbar/>
            </div>
            <div
                className="mx-auto my-16 h-screen overflow-y-auto w-full bg-gray-900 p-6 rounded-lg shadow-lg w-full md:w-2/3">
                <h2 className="text-2xl font-bold mb-4 text-center">Upload Image</h2>
                <form onSubmit={handleUpload} className="space-y-4">
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className="w-full p-2 border border-gray-800 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                        type="submit"
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Upload
                    </button>
                </form>
                {message && <p className="mt-4 text-center text-gray-700">{message}</p>}
            </div>
        </div>
    );
}

export default UploadPage;
