import React, {useState, useEffect, useRef, useContext} from "react";
import {useParams, useNavigate} from "react-router-dom";
import Navbar from "../../Components/Navbar";
import {LoginContext} from "../../Contexts/LoginContextProvider";
import {toast} from "react-toastify";

function VariantNew({vars, productId}) {
    const [variants, setVariants] = useState(vars)
    const [variant, setVariant] = useState({id: 0, variantName: "", order: 0, options: []});

    const [newOption, setNewOption] = useState({
        id: 0,
        variantName: "",
        image: "",
        price: 0,
        stock: 0,
    });

    useEffect(() => {
        console.log(vars);
    }, [])

    const handleBulkSaveVariants = async (idPop) => {
        let vrCopy = variant;
        vrCopy.id = idPop;
        let varcopy = variants;
        varcopy.push(vrCopy);

        const updatedVariants = variants.map((variant) => ({
            id: variant.id || null,
            name: variant.variantName,
            order: variant.order || 0,
            options: variant.options || [],
        }));

        toast.promise(
            fetch(`https://v2.whustle.com/products/${productId}/variants/bulk`, {
                method: "PUT",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({variants: updatedVariants}),
            }),
            {
                pending: "Saving product variants...",
                success: "Variants saved!",
                error: "Failed to save variants!"
            }
        ).then(() => {
            setVariants(vars);
            setVariant({id: 0, variantName: "", order: 0, options: []});
        })
    };


    const {loginState, getToken} = useContext(LoginContext);
    const token = getToken();

    const handleSaveVariant = () => {
        const method = "POST";
        const endpoint ="https://v2.whustle.com/variants";

        fetch(endpoint, {
            method,
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: variant.variantName, order: variant.order}),
        })
            .then((res) => res.json())
            .then((data) => {
                setVariant((prev) => ({
                    ...prev,
                    options: [...prev.options, {...newOption, id: data.id}],
                }));
                setNewOption({name: "", image: "", price: "", stock: ""});
                console.log("data:");
                console.log(data);
                handleBulkSaveVariants(data.id);
            })
            .catch((error) => console.error("Error saving variant:", error));
    };

    const handleAddOption = () => {
        fetch(`https://v2.whustle.com/variants/${variant.id}/options`, {
            method: "POST",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newOption),
        })
            .then((res) => res.json())
            .then((data) => {
                setVariant((prev) => ({
                    ...prev,
                    options: [...prev.options, {...newOption, id: data.id}],
                }));
                setNewOption({name: "", image: "", price: "", stock: ""});
            })
            .catch((error) => console.error("Error adding option:", error));
    };

    const handleDeleteOption = (optionId) => {
        fetch(`https://v2.whustle.com/variants/options/${optionId}`, {
            method: "DELETE",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                setVariant((prev) => ({
                    ...prev,
                    options: prev.options.filter((option) => option.id !== optionId),
                }));
            })
            .catch((error) => console.error("Error deleting option:", error));
    };

    return (
        <div>
            <div className="pt-4 overflow-y-">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-6">
                   Create New Variant
                </h1>

                <div className="mb-6">
                    <label className="block text-gray-300 font-medium mb-2">
                        Variant Name
                    </label>
                    <input
                        type="text"
                        value={variant.variantName}
                        onChange={(e) =>
                            setVariant((prev) => ({...prev, variantName: e.target.value}))
                        }
                        className="w-full border border-gray-700 bg-gray-800 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="mb-6">
                    <label className="block text-gray-300 font-medium mb-2">
                        Order
                    </label>
                    <input
                        type="number"
                        value={variant.order}
                        onChange={(e) =>
                            setVariant((prev) => ({
                                ...prev,
                                order: Number(e.target.value),
                            }))
                        }
                        className="w-full border border-gray-700 bg-gray-800 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="mt-8">
                    <button
                        onClick={handleSaveVariant}
                        className="bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg px-6 py-3"
                    >
                        Save Variant
                    </button>
                </div>
            </div>
        </div>
    );
}

export default VariantNew;
