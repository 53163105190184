import logo from './logo.svg';
import './App.css';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import CDN from "./Pages/Media/CDN";
import ProductList from "./Pages/Product/Lists/ProductList";
import EditProduct from "./Pages/Product/Editors/EditProduct";
import VariantsList from "./Pages/Product/Lists/VariantsList";
import EditVariant from "./Pages/Product/Editors/EditVariant";
import CreateProduct from "./Pages/Product/CreateProduct";
import CollectionsList from "./Pages/Product/Lists/CollectionsList";
import CreateCollection from "./Pages/Product/CreateCollection";
import EditCollection from "./Pages/Product/Editors/EditCollection";
import ReviewsList from "./Pages/Product/Lists/ReviewsList";
import CreateReview from "./Pages/Product/CreateReview";
import EditReview from "./Pages/Product/Editors/EditReview";
import WebsitePreview from "./Pages/Settings/WebsitePreview";
import Login from "./Pages/Login";
import {LoginContextProvider} from "./Contexts/LoginContextProvider";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GenerateReviews from "./Pages/Product/GenerateReviews";
import Upload from "./Pages/Media/Upload";
import UpdateChecker from "./Pages/Settings/UpdateChecker";
import CustomerList from "./Pages/Management/CustomersList";
import OrdersList from "./Pages/Management/OrdersList";
import {PaginationProvider} from "./Contexts/PaginationContext";
import OrderStats from "./Pages/Management/OrderStats";
import Finance from "./Pages/Management/Finance";

function App() {
    return (
        <BrowserRouter>
            <LoginContextProvider>
                <PaginationProvider>
                <div className={"bg-black flex flex-col min-h-screen"}>
                    <div className="bg-black flex flex-col items-center x">
                        <div className={"w-full"}>
                            <div className="fixed top-4 right-4 flex flex-col-reverse items-end space-y-4 pb-4 z-50">
                                <ToastContainer/>
                            </div>
                            <Routes>
                                <Route path="/" element={<Dashboard title={"Overview"}/>}/>
                                <Route path="/cdn" element={<CDN title={"CDN"}/>}/>
                                <Route path="/list" element={<ProductList title={"Products"}/>}/>
                                <Route path="/edit/:id" element={<EditProduct title={"Edit Product"}/>}/>
                                <Route path="/variantsList" element={<VariantsList title={"Variants"}/>}/>
                                <Route path="/variants/:id/edit" element={<EditVariant title={"Edit Variant"}/>}/>
                                <Route path="/variants/:id" element={<EditVariant title={"Edit Variant"}/>}/>
                                <Route path="/create" element={<CreateProduct title={"Create Product"}/>}/>
                                <Route path="/collectionsList" element={<CollectionsList title={"Collections"}/>}/>
                                <Route path="/collectionsEdit/create"
                                       element={<CreateCollection title={"Create Collection"}/>}/>
                                <Route path="/collectionsEdit/edit/:id"
                                       element={<EditCollection title={"Edit Collection"}/>}/>
                                <Route path="/reviews" element={<ReviewsList title={"Reviews"}/>}/>
                                <Route path="/reviews/create" element={<CreateReview title={"Create Review"}/>}/>
                                <Route path="/reviews/edit/:id" element={<EditReview title={"Edit Review"}/>}/>
                                <Route path="/preview" element={<WebsitePreview title={"Preview"}/>}/>
                                <Route path="/login" element={<Login title={"Login"}/>}/>
                                <Route path="/upload" element={<Upload title={"Upload image"}/>}/>
                                <Route path="/generateReviews" element={<GenerateReviews title={"Generate Reviews"}/>}/>
                                <Route path="/update" element={<UpdateChecker title={"Update"}/>}/>
                                <Route path="/customers" element={<CustomerList title={"Customers"}/>}/>
                                <Route path="/orders" element={<OrdersList title={"Orders"}/>}/>
                                <Route path="/finance" element={<Finance title={"Finance"}/>}/>
                                <Route path="/stats" element={<OrderStats title={"Orders"}/>}/>
                                {/* Přidání trasy pro neexistující stránky */}
                                <Route path="*" element={<Dashboard title={"Overview"}/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
                </PaginationProvider>
            </LoginContextProvider>
        </BrowserRouter>
    );
}

export default App;
