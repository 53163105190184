import React, { useState, useEffect, useContext, useRef } from 'react';
import Navbar from "../../Components/Navbar";
import { toast } from 'react-toastify';
import { LoginContext } from "../../Contexts/LoginContextProvider";

function UpdateChecker({ title }) {
    const [isChecking, setIsChecking] = useState(false);
    const [hasUpdates, setHasUpdates] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);

    const { loginState, getToken } = useContext(LoginContext);
    const token = getToken(); // Retrieve the token from context
    const hasCheckedUpdates = useRef(false); // Prevent multiple executions

    const checkForUpdates = async () => {
        if (isChecking) return;
        setIsChecking(true);

        const promise = fetch('https://v2.whustle.com/check-version', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        })
            .then(async (response) => {
                const data = await response.json();
                if (response.ok) {
                    setHasUpdates(data.hasUpdates);
                    return data.hasUpdates
                        ? '🚀 Updates are available for this project.'
                        : '✅ The project is up-to-date.';
                } else {
                    throw new Error(data.error || 'Failed to check updates.');
                }
            })
            .catch((error) => {
                console.error('Error checking updates:', error);
                throw new Error('An error occurred while checking for updates.');
            })
            .finally(() => {
                setIsChecking(false);
            });

        toast.promise(promise, {
            pending: '🔎 Checking for updates...',
            success: {
                render({ data }) {
                    return data;
                },
            },
            error: {
                render({ data }) {
                    return data.message;
                },
            },
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;

        if (loginState.initialized && loginState.loggedIn && token && !hasCheckedUpdates.current) {
            hasCheckedUpdates.current = true; // Mark as executed
            checkForUpdates();
        }

        return () => {
            document.title = "Admin Panel"; // Reset title on component unmount
        };
    }, [title, loginState.initialized, loginState.loggedIn, token]);

    const updateProject = async () => {
        setIsUpdating(true);

        const promise = fetch('https://v2.whustle.com/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        })
            .then(async (response) => {
                const data = await response.json();
                if (response.ok) {
                    setHasUpdates(false);
                    return '✨ Project updated successfully.';
                } else {
                    throw new Error(data.error || 'Failed to update the project.');
                }
            })
            .catch((error) => {
                console.error('Error updating project:', error);
                throw new Error('An error occurred while updating the project.');
            })
            .finally(() => {
                setIsUpdating(false);
            });

        toast.promise(promise, {
            pending: '⚙️ Updating the project...',
            success: {
                render({ data }) {
                    return data;
                },
            },
            error: {
                render({ data }) {
                    return data.message;
                },
            },
        });
    };

    return (
        <div className="flex md:flex-row flex-col w-full bg-gray-900 text-gray-100 min-h-screen overflow-y-auto">
            <Navbar />
            <div className="w-full p-4 md:p-8 md:w-2/3 mx-auto">
                <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-100">🔧 Update Checker</h1>
                <button
                    onClick={checkForUpdates}
                    disabled={isChecking}
                    className={`px-4 py-2 mb-4 font-medium text-sm rounded-lg ${
                        isChecking
                            ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                            : "bg-blue-600 text-white hover:bg-blue-700"
                    }`}
                >
                    {isChecking ? "Checking..." : "🔄 Check for Updates"}
                </button>
                {hasUpdates !== null && (
                    <p className="mb-4 text-gray-300">
                        {hasUpdates
                            ? "🚀 Updates are available for this project."
                            : "✅ The project is up-to-date."}
                    </p>
                )}
                <button
                    onClick={updateProject}
                    disabled={!hasUpdates || isUpdating}
                    className={`px-4 py-2 font-medium text-sm rounded-lg ${
                        !hasUpdates || isUpdating
                            ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                            : "bg-green-600 text-white hover:bg-green-700"
                    }`}
                >
                    {isUpdating ? "Updating..." : "⚡ Update Project"}
                </button>
            </div>
        </div>
    );
}

export default UpdateChecker;
