import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar";
import { LoginContext } from "../../../Contexts/LoginContextProvider";
import { PaginationContext } from "../../../Contexts/PaginationContext";

function VariantsListEmbedded({vars}) {
    const [variants, setVariants] = useState(vars)
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [stateRestored, setStateRestored] = useState(false);
    const variantsPerPage = 7;
    const navigate = useNavigate();

    const { loginState, getToken } = useContext(LoginContext);
    const { savePaginationState, getPaginationState } = useContext(PaginationContext);
    const token = getToken();

    useEffect(() => {
        console.log(variants);
    }, []);

    // Restore state on mount
    useEffect(() => {
        const savedState = getPaginationState("variants");
        setSearchQuery(savedState.filter || "");
        setCurrentPage(savedState.currentPage || 1);
        setStateRestored(true);
    }, [getPaginationState]);

    // Filter variants based on search query
    const filteredVariants = variants.filter((variant) =>
        variant.id.toString().includes(searchQuery) ||
        (variant.name && variant.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    // Pagination logic
    const indexOfLastVariant = currentPage * variantsPerPage;
    const indexOfFirstVariant = indexOfLastVariant - variantsPerPage;
    const currentVariants = filteredVariants.slice(indexOfFirstVariant, indexOfLastVariant);
    const totalPages = Math.ceil(filteredVariants.length / variantsPerPage);

    const handleSearchChange = (e) => {
        const newSearchQuery = e.target.value;
        setSearchQuery(newSearchQuery);
        setCurrentPage(1);
        savePaginationState("variants", { currentPage: 1, filter: newSearchQuery });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        savePaginationState("variants", { currentPage: newPage, filter: searchQuery });
    };

    const handleDeleteVariant = (id) => {
        if (window.confirm("Are you sure you want to delete this variant?")) {
            fetch(`https://v2.whustle.com/variants/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            })
                .then(() => setVariants((prev) => prev.filter((variant) => variant.id !== id)))
                .catch((error) => console.error("Error deleting variant:", error));
        }
    };

    const handleNavigate = (path) => {
        savePaginationState("variants", { currentPage, filter: searchQuery });
        navigate(path);
    };

    return (
        <div>
            <div className="pt-5">

                <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-gray-100">
                    Added Variants
                </h1>
                {/* Search Input */}
                <div className="mb-6">
                    <input
                        type="text"
                        placeholder="Search by ID or Name"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="border border-gray-700 p-2 rounded-lg w-full bg-gray-800 text-gray-100 placeholder-gray-400"
                    />
                </div>

                {/* Variants Table */}
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-800">
                    <table className="w-full text-sm text-left text-gray-200">
                        <thead className="text-xs uppercase bg-gray-700 text-gray-300">
                        <tr>
                            <th className="px-4 py-2">#</th>
                            <th className="px-4 py-2">Id</th>
                            <th className="px-4 py-2">Name</th>
                            <th className="px-4 py-2">Order</th>
                            <th className="px-4 py-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentVariants.map((variant, index) => (
                            <tr key={variant.id} className="border-b border-gray-700 hover:bg-gray-700">
                                <td className="px-4 py-2">{indexOfFirstVariant + index + 1}</td>
                                <td className="px-4 py-2">{variant.id}</td>
                                <td className="px-4 py-2">{variant.variantName}</td>
                                <td className="px-4 py-2">{variant.order}</td>
                                <td className="px-4 py-2 flex gap-2">
                                    <button
                                        onClick={() => handleNavigate(`/variants/${variant.id}/edit`)}
                                        className="text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDeleteVariant(variant.id)}
                                        className="text-white bg-red-600 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {currentVariants.length === 0 && (
                            <tr>
                                <td colSpan="5" className="px-4 py-2 text-gray-300 text-center">
                                    No variants found.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                {filteredVariants.length > 0 && (
                    <div className="flex justify-between items-center mt-4">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === 1
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            Previous
                        </button>
                        <span className="text-gray-300 font-medium">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === totalPages
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default VariantsListEmbedded;
