import React, {useState, useRef, useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import {LoginContext} from "../../Contexts/LoginContextProvider";

function CreateReview({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: "",
        title: "",
        content: "",
        rating: "",
        verified: false,
        product_id: "",
        date: "",
        avatar_url: "https://avatar.iran.liara.run/username?username=filip+horak",
        store_id: 2,
    });
    const [images, setImages] = useState([]);
    const fileInputRef = useRef(null);
    const { getToken, loginState } = useContext(LoginContext);
    const token = getToken();

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm((prev) => {
            const updatedForm = {
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            };

            // Update avatar URL based on the `name` field
            if (name === "name") {
                const words = value.trim().split(/\s+/);
                let initials = "";
                if (words.length === 1) {
                    initials = words[0].charAt(0) + "+" + words[0].slice(-1);
                } else if (words.length >= 2) {
                    initials = words[0].charAt(0) + "+" + words[1].charAt(0);
                }
                const avatarUrl = `https://avatar.iran.liara.run/username?username=${initials}`;
                updatedForm.avatar_url = avatarUrl;
            }

            return updatedForm;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://v2.whustle.com/reviews", {
            method: "POST",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...form }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (images.length > 0) {
                    // Upload images after review is created
                    const formData = new FormData();
                    images.forEach((image) => formData.append("images", image));
                    fetch(
                        `https://v2.whustle.com/reviews/${data.reviewId}/images`,
                        {
                            method: "POST",
                            body: formData,
                            headers: {
                                'Authorization': token,
                            }
                        }
                    )
                        .then(() => navigate("/reviews"))
                        .catch((err) => console.error("Error uploading images:", err));
                } else {
                    navigate("/reviews");
                }
            })
            .catch((error) => console.error("Error creating review:", error));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setImages((prev) => [...prev, ...files]);
        fileInputRef.current.value = "";
    };

    const removeImage = (index) => {
        setImages((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-gray-100 min-h-screen overflow-hidden">
            <Navbar />
            <div className="p-4 md:p-8 mx-auto md:w-2/3 w-full">
                <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-100">
                    Create Review
                </h1>

                <form
                    onSubmit={handleSubmit}
                    className="bg-gray-800 rounded-lg shadow p-6 space-y-6"
                >
                    {/* Name and Title */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-gray-300 font-medium mb-2"
                            >
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={form.name}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="title"
                                className="block text-gray-300 font-medium mb-2"
                            >
                                Title
                            </label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                value={form.title}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                    </div>

                    {/* Product ID and Date */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label
                                htmlFor="product_id"
                                className="block text-gray-300 font-medium mb-2"
                            >
                                Product ID
                            </label>
                            <input
                                type="number"
                                id="product_id"
                                name="product_id"
                                value={form.product_id}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="date"
                                className="block text-gray-300 font-medium mb-2"
                            >
                                Date
                            </label>
                            <input
                                type="date"
                                id="date"
                                name="date"
                                value={form.date}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                    </div>

                    {/* Content */}
                    <div>
                        <label
                            htmlFor="content"
                            className="block text-gray-300 font-medium mb-2"
                        >
                            Content
                        </label>
                        <textarea
                            id="content"
                            name="content"
                            value={form.content}
                            onChange={handleInputChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            rows="4"
                            required
                        ></textarea>
                    </div>

                    {/* Rating and Verified */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label
                                htmlFor="rating"
                                className="block text-gray-300 font-medium mb-2"
                            >
                                Rating
                            </label>
                            <input
                                type="number"
                                id="rating"
                                name="rating"
                                value={form.rating}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                min="1"
                                max="5"
                                required
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id="verified"
                                name="verified"
                                checked={form.verified}
                                onChange={handleInputChange}
                                className="h-4 w-4 text-blue-600 border-gray-700 rounded"
                            />
                            <label
                                htmlFor="verified"
                                className="text-gray-300 font-medium"
                            >
                                Verified
                            </label>
                        </div>
                    </div>

                    {/* Avatar URL */}
                    <div>
                        <label
                            htmlFor="avatar_url"
                            className="block text-gray-300 font-medium mb-2"
                        >
                            Avatar URL
                        </label>
                        <input
                            type="url"
                            id="avatar_url"
                            name="avatar_url"
                            value={form.avatar_url}
                            onChange={handleInputChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    {/* Images Section */}
                    <div>
                        <label
                            htmlFor="images"
                            className="block text-gray-300 font-medium mb-2"
                        >
                            Images
                        </label>
                        <div className="flex gap-4 flex-wrap mb-4">
                            {images.map((image, index) => (
                                <div key={index} className="relative">
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt="Preview"
                                        className="w-20 h-20 object-cover rounded-lg"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => removeImage(index)}
                                        className="absolute top-0 right-0 bg-red-600 text-white text-sm rounded-full p-1"
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            multiple
                            className="block w-full text-gray-300"
                        />
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg px-4 py-2"
                    >
                        Create Review
                    </button>
                </form>
            </div>
        </div>
    );
}

export default CreateReview;
