import React, {useState, useEffect, useRef, useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Navbar from "../../../Components/Navbar";
import {LoginContext} from "../../../Contexts/LoginContextProvider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {toast} from 'react-toastify';
import AdditionalImages from "../../../Components/AdditionalImages";
import MinimalAdditionalImages from "../../../Components/AdditionalImages";
import EditVariant from "./EditVariant";
import VariantNew from "../VariantNew";
import VariantsListEmbedded from "../Lists/VariantsListEmbedded";

function EditProduct({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [collections, setCollections] = useState([]);
    const [tags, setTags] = useState([]);
    const [variants, setVariants] = useState([]);
    const [allVariants, setAllVariants] = useState([]);
    const [selectedVariantId, setSelectedVariantId] = useState("");
    const {id: productId} = useParams();

    const fileInputRef = useRef(null);
    const {loginState, getToken} = useContext(LoginContext);
    const token = getToken();

    const fetchData = () => {
        fetch(`https://v2.whustle.com/product/${productId}`, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if(data.title && data.title.length > 0){
                    document.title = `${data.title} - Admin Panel`;
                }
                setProduct(data);
                setVariants(data.variants || []);
            })
            .catch((error) => console.error("Error fetching product:", error));

        fetch("https://v2.whustle.com/collections", {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then(setCollections)
            .catch((error) => console.error("Error fetching collections:", error));

        fetch("https://v2.whustle.com/variants", {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then(setAllVariants)
            .catch((error) => console.error("Error fetching variants:", error));

        fetch("https://v2.whustle.com/tags", {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then(setTags)
            .catch((error) => console.error("Error fetching tags:", error));
    };

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchData();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setProduct((prev) => ({...prev, [name]: value}));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file);

        fetch(`https://v2.whustle.com/products/${productId}/image`, {
            method: "POST",
            body: formData,
            headers: {
                'Authorization': token
            }
        })
            .then((res) => res.json())
            .then((data) => setProduct((prev) => ({...prev, img: data.imgUrl})))
            .catch((error) => console.error("Error updating image:", error));
    };

    const handleBulkSaveVariants = async () => {
        const updatedVariants = variants.map((variant) => ({
            id: variant.id || null,
            name: variant.variantName,
            order: variant.order || 0,
        }));

        toast.promise(
            fetch(`https://v2.whustle.com/products/${productId}/variants/bulk`, {
                method: "PUT",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({variants: updatedVariants}),
            }),
            {
                pending: "Saving product variants...",
                success: "Variants saved!",
                error: "Failed to save variants!"
            }
        );
    };

    const handleBulkDeleteVariants = async () => {
        try {
            const variantIdsToDelete = variants.map((variant) => variant.id);

            await fetch(`https://v2.whustle.com/products/${productId}/variants/bulk`, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({variantIds: variantIdsToDelete}),
            });

            setVariants([]);
            alert("Variants deleted successfully.");
        } catch (error) {
            console.error("Error bulk deleting variants:", error);
            alert("Failed to delete variants. Check console for details.");
        }
    };

    const deleteImage = async (id) => {
        await fetch(`https://v2.whustle.com/products/images/` + id, {
            method: "DELETE",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            }
        });
    };

    const handleSaveChanges = async () => {
        toast.promise(
            fetch(`https://v2.whustle.com/products/${productId}`, {
                method: "PUT",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    title: product.title,
                    desc: product.desc,
                    price: product.price,
                    stock: product.stock,
                    handle: product.product_handle,
                }),
            }),
            {
                pending: "Saving product...",
                success: "Product saved!",
                error: "Failed to save product!"
            }
        );

        // Update collections
        fetch(`https://v2.whustle.com/products/${productId}/collections`, {
            method: "PUT",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({collectionIds: product.collections.map((c) => c.id)}),
        });

        // Update tags
        fetch(`https://v2.whustle.com/products/${productId}/tags`, {
            method: "PUT",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({tagIds: product.tags.map((t) => t.id)}),
        });
    };

    const handleDescriptionChange = (value) => {
        setProduct((prev) => ({...prev, desc: value}));
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-gray-100 min-h-screen overflow-y-auto">
            <Navbar/>
            <div className="p-4 md:p-8 overflow-y-auto h-screen mx-auto max-w-5xl">
                {product && (
                    <>
                        <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-6">
                            Edit Product: <span className="text-blue-500">{product.title}</span>
                        </h1>

                        {/* Product Info Card */}
                        <div className="bg-gray-800 rounded-lg shadow p-6 mb-6">
                            <h2 className="text-lg font-semibold text-gray-100 mb-4">
                                Product Details
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-300 mb-2">Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={product.title}
                                        onChange={handleInputChange}
                                        className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-300 mb-2">Price</label>
                                    <input
                                        type="number"
                                        name="price"
                                        value={product.price}
                                        onChange={handleInputChange}
                                        className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-300 mb-2">Stock</label>
                                    <input
                                        type="number"
                                        name="stock"
                                        value={product.stock}
                                        onChange={handleInputChange}
                                        className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-300 mb-2">
                                        Description
                                    </label>
                                    <div className="bg-gray-900 rounded-lg">
                                        <ReactQuill
                                            value={product.desc || ""}
                                            onChange={handleDescriptionChange}
                                            theme="snow"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-gray-300 mb-2">
                                        Product Handle
                                    </label>
                                    <textarea
                                        name="product_handle"
                                        value={product.product_handle}
                                        onChange={handleInputChange}
                                        className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-300 mb-2">Image</label>
                                    {product.img && (
                                        <img
                                            className="w-32 h-32 rounded-lg object-cover mb-4"
                                            src={product.img}
                                            alt="Product"
                                        />
                                    )}
                                    <input
                                        type="file"
                                        onChange={handleImageChange}
                                        className="block text-sm text-gray-300"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Collections Section */}
                        <div className="bg-gray-800 rounded-lg shadow p-6 mt-8">
                            <h2 className="text-lg font-semibold text-gray-100 mb-4">Collections</h2>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {product.collections?.map((collection) => (
                                    <div
                                        key={collection.id}
                                        className="flex items-center bg-gray-700 text-gray-100 px-3 py-1 rounded-lg"
                                    >
                                        {collection.title}
                                        <button
                                            onClick={() =>
                                                setProduct((prev) => ({
                                                    ...prev,
                                                    collections: prev.collections.filter((c) => c.id !== collection.id),
                                                }))
                                            }
                                            className="ml-2 text-red-400 font-bold"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <select
                                    onChange={(e) => {
                                        const selectedId = parseInt(e.target.value, 10);
                                        const selectedCollection = collections.find((c) => c.id === selectedId);
                                        if (
                                            selectedCollection &&
                                            !product.collections?.some((c) => c.id === selectedId)
                                        ) {
                                            setProduct((prev) => ({
                                                ...prev,
                                                collections: [...prev.collections, selectedCollection],
                                            }));
                                        }
                                    }}
                                    className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                >
                                    <option value="">Add Collection</option>
                                    {collections
                                        .filter(
                                            (collection) =>
                                                !product.collections?.some((c) => c.id === collection.id)
                                        )
                                        .map((collection) => (
                                            <option key={collection.id} value={collection.id}>
                                                {collection.title}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>


                        {/* Tags Section */}
                        <div className="bg-gray-800 rounded-lg shadow p-6 mt-8">
                            <h2 className="text-lg font-semibold text-gray-100 mb-4">Tags</h2>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {tags
                                    .filter((tag) => product.tags?.some((t) => t.id === tag.id))
                                    .map((tag) => (
                                        <div
                                            key={tag.id}
                                            className="flex items-center bg-gray-700 text-gray-100 px-3 py-1 rounded-lg"
                                        >
                                            {tag.name}
                                            <button
                                                onClick={() =>
                                                    setProduct((prev) => ({
                                                        ...prev,
                                                        tags: prev.tags.filter((t) => t.id !== tag.id),
                                                    }))
                                                }
                                                className="ml-2 text-red-400 font-bold"
                                            >
                                                ×
                                            </button>
                                        </div>
                                    ))}
                            </div>
                            <select
                                onChange={(e) => {
                                    const selectedId = parseInt(e.target.value, 10);
                                    const selectedTag = tags.find((t) => t.id === selectedId);
                                    if (selectedTag && !product.tags?.some((t) => t.id === selectedId)) {
                                        setProduct((prev) => ({
                                            ...prev,
                                            tags: [...prev.tags, selectedTag],
                                        }));
                                    }
                                }}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">Add Tag</option>
                                {tags
                                    .filter((tag) => !product.tags?.some((t) => t.id === tag.id))
                                    .map((tag) => (
                                        <option key={tag.id} value={tag.id}>
                                            {tag.name}
                                        </option>
                                    ))}
                            </select>
                        </div>


                        {/* Variants Section */}
                        <div className="bg-gray-800 rounded-lg shadow p-6 mt-8">
                            <h2 className="text-lg font-semibold text-gray-100 mb-4">Variants</h2>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {variants.map((variant) => (
                                    <div
                                        key={variant.id}
                                        className="flex items-center bg-gray-700 text-gray-100 px-3 py-1 rounded-lg"
                                    >
                                        {variant.variantName} - {variant.id}
                                        <button
                                            onClick={() =>
                                                setVariants((prev) => prev.filter((v) => v.id !== variant.id))
                                            }
                                            className="ml-2 text-red-400 font-bold"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div className="flex items-center gap-4">
                                <select
                                    value={selectedVariantId}
                                    onChange={(e) => setSelectedVariantId(e.target.value)}
                                    className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                >
                                    <option value="">Select Variant</option>
                                    {allVariants.map((variant) => (
                                        <option key={variant.id} value={variant.id}>
                                            {variant.name} - {variant.id}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    onClick={() => {
                                        const selectedVariant = allVariants.find(
                                            (v) => v.id === parseInt(selectedVariantId, 10)
                                        );
                                        if (selectedVariant) {
                                            setVariants((prev) => [...prev, selectedVariant]);
                                        }
                                    }}
                                    className="bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg px-4 py-2"
                                >
                                    Add
                                </button>
                            </div>

                            <div className="flex gap-4 mt-4">
                                <button
                                    onClick={handleBulkSaveVariants}
                                    className="bg-green-600 hover:bg-green-700 text-white font-medium rounded-lg px-4 py-2"
                                >
                                    Save Variants
                                </button>
                            </div>
                            <VariantNew vars={product.variants} productId={product.id} />
                            <VariantsListEmbedded vars={product.variants} />
                        </div>

                        {/* Additional Images Section */}
                        <AdditionalImages
                            product={product}
                            setProduct={setProduct}
                            deleteImage={deleteImage}
                            productId={productId}
                            token={token}
                        />
                        <a
                            href={`https://${loginState.web}/products/${product.product_handle}`}
                            target="_blank"
                            className="mt-4 mr-4 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-lg px-4 py-2" rel="noreferrer"
                        >
                            View in store
                        </a>
                        <button
                            onClick={handleSaveChanges}
                            className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg px-4 py-2"
                        >
                            Save Changes
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

export default EditProduct;
