import { NavLink } from "react-router-dom";
import {
    FaChartBar,
    FaHome,
    FaImages,
    FaProductHunt,
    FaTags,
    FaBars,
    FaInternetExplorer,
    FaCloudUploadAlt,
} from "react-icons/fa";
import React, { useContext, useState } from "react";
import { LoginContext } from "../Contexts/LoginContextProvider";
import { CiLogout } from "react-icons/ci";
import { MdBrowserUpdated, MdOutlineSupervisorAccount } from "react-icons/md";
import { TbPackages } from "react-icons/tb";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({});

    const toggleDropdown = (key) => {
        setDropdownOpen((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const { logout } = useContext(LoginContext);

    return (
        <div className="flex flex-col">
            {/* Navbar for Mobile (Horizontal) */}
            <div className="bg-gray-800 text-white w-full z-10 md:hidden">
                <div className="p-4 flex items-center justify-between">
                    <h1 className="text-xl font-bold">Dashboard</h1>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="text-white focus:outline-none"
                    >
                        <FaBars className="w-6 h-6" />
                    </button>
                </div>
                {isOpen && (
                    <div className="bg-gray-800 flex flex-col space-y-2 px-4 py-2">
                        <NavLink
                            to="/dashboard"
                            className={({ isActive }) =>
                                `block px-4 py-2 rounded-md ${
                                    isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                }`
                            }
                        >
                            <FaHome className="inline-block mr-2" />
                            Overview
                        </NavLink>
                        {/* Products Dropdown */}
                        <div>
                            <button
                                onClick={() => toggleDropdown("products")}
                                className="flex items-center justify-between w-full px-4 py-2 text-left hover:bg-gray-700 rounded-md"
                            >
                                <span><FaProductHunt className="inline-block mr-2" /> Products</span>
                            </button>
                            {dropdownOpen["products"] && (
                                <div className="ml-4 space-y-2">
                                    <NavLink
                                        to="/list"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Product List
                                    </NavLink>
                                    <NavLink
                                        to="/collectionsList"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Collections
                                    </NavLink>
                                    <NavLink
                                        to="/variantsList"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Variants
                                    </NavLink>
                                    <NavLink
                                        to="/reviews"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Reviews
                                    </NavLink>
                                </div>
                            )}
                        </div>

                        {/* Media Dropdown */}
                        <div>
                            <button
                                onClick={() => toggleDropdown("media")}
                                className="flex items-center justify-between w-full px-4 py-2 text-left hover:bg-gray-700 rounded-md"
                            >
                                <span><FaImages className="inline-block mr-2" /> Media</span>
                            </button>
                            {dropdownOpen["media"] && (
                                <div className="ml-4 space-y-2">
                                    <NavLink
                                        to="/cdn"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Images
                                    </NavLink>
                                    <NavLink
                                        to="/upload"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Upload Image
                                    </NavLink>
                                </div>
                            )}
                        </div>

                        {/* Orders Dropdown */}
                        <div>
                            <button
                                onClick={() => toggleDropdown("orders")}
                                className="flex items-center justify-between w-full px-4 py-2 text-left hover:bg-gray-700 rounded-md"
                            >
                                <span><TbPackages className="inline-block mr-2" /> Management</span>
                            </button>
                            {dropdownOpen["orders"] && (
                                <div className="ml-4 space-y-2">
                                    <NavLink
                                        to="/stats"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Statistics
                                    </NavLink>
                                    <NavLink
                                        to="/orders"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Orders
                                    </NavLink>
                                    <NavLink
                                        to="/customers"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Customers
                                    </NavLink>
                                    <NavLink
                                        to="/finance"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Finances
                                    </NavLink>
                                </div>
                            )}
                        </div>

                        {/* Settings Dropdown */}
                        <div>
                            <button
                                onClick={() => toggleDropdown("settings")}
                                className="flex items-center justify-between w-full px-4 py-2 text-left hover:bg-gray-700 rounded-md"
                            >
                                <span><MdBrowserUpdated className="inline-block mr-2" /> Settings</span>
                            </button>
                            {dropdownOpen["settings"] && (
                                <div className="ml-4 space-y-2">
                                    <NavLink
                                        to="/update"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Update Project
                                    </NavLink>
                                    <NavLink
                                        to="/preview"
                                        className={({ isActive }) =>
                                            `block px-4 py-2 rounded-md ${
                                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                            }`
                                        }
                                    >
                                        Preview Website
                                    </NavLink>
                                </div>
                            )}
                        </div>

                        <NavLink
                            to="/logout"
                            onClick={() => logout()}
                            className={({ isActive }) =>
                                `block px-4 py-3 rounded-md ${
                                    isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                }`
                            }
                        >
                            <CiLogout className="inline-block mr-2" />
                            LogOut
                        </NavLink>
                    </div>
                )}
            </div>

            {/* Sidebar for Desktop (Vertical) */}
            <div className="hidden min-h-screen md:flex md:flex-col md:w-64 md:bg-gray-800 md:text-white">
                <div className="p-4 text-center border-b border-gray-700">
                    <h1 className="text-2xl font-bold">Dashboard</h1>
                </div>
                <nav className="flex-1 mt-6 space-y-2 px-4">
                    <NavLink
                        to="/dashboard"
                        className={({ isActive }) =>
                            `block px-4 py-3 rounded-md ${
                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                            }`
                        }
                    >
                        <FaHome className="inline-block mr-2" />
                        Overview
                    </NavLink>

                    {/* Products Dropdown */}
                    <div>
                        <button
                            onClick={() => toggleDropdown("products")}
                            className="flex items-center justify-between w-full px-4 py-3 text-left hover:bg-gray-700 rounded-md"
                        >
                            <span><FaProductHunt className="inline-block mr-2" /> Products</span>
                        </button>
                        {dropdownOpen["products"] && (
                            <div className="ml-4 space-y-2">
                                <NavLink
                                    to="/list"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Product List
                                </NavLink>
                                <NavLink
                                    to="/collectionsList"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Collections
                                </NavLink>
                                <NavLink
                                    to="/variantsList"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Variants
                                </NavLink>
                                <NavLink
                                    to="/reviews"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Reviews
                                </NavLink>
                            </div>
                        )}
                    </div>

                    {/* Media Dropdown */}
                    <div>
                        <button
                            onClick={() => toggleDropdown("media")}
                            className="flex items-center justify-between w-full px-4 py-3 text-left hover:bg-gray-700 rounded-md"
                        >
                            <span><FaImages className="inline-block mr-2" /> Media</span>
                        </button>
                        {dropdownOpen["media"] && (
                            <div className="ml-4 space-y-2">
                                <NavLink
                                    to="/cdn"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Images
                                </NavLink>
                                <NavLink
                                    to="/upload"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Upload Image
                                </NavLink>
                            </div>
                        )}
                    </div>


                    {/* Orders Dropdown */}
                    <div>
                        <button
                            onClick={() => toggleDropdown("orders")}
                            className="flex items-center justify-between w-full px-4 py-3 text-left hover:bg-gray-700 rounded-md"
                        >
                            <span><TbPackages className="inline-block mr-2" /> Management</span>
                        </button>
                        {dropdownOpen["orders"] && (
                            <div className="ml-4 space-y-2">
                                <NavLink
                                    to="/stats"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Statistics
                                </NavLink>
                                <NavLink
                                    to="/orders"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                   Orders
                                </NavLink>
                                <NavLink
                                    to="/customers"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Customers
                                </NavLink>
                                <NavLink
                                    to="/finance"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Finances
                                </NavLink>
                            </div>
                        )}
                    </div>

                    {/* Settings Dropdown */}
                    <div>
                        <button
                            onClick={() => toggleDropdown("settings")}
                            className="flex items-center justify-between w-full px-4 py-3 text-left hover:bg-gray-700 rounded-md"
                        >
                            <span><MdBrowserUpdated className="inline-block mr-2" /> Settings</span>
                        </button>
                        {dropdownOpen["settings"] && (
                            <div className="ml-4 space-y-2">
                                <NavLink
                                    to="/update"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Update Project
                                </NavLink>
                                <NavLink
                                    to="/preview"
                                    className={({ isActive }) =>
                                        `block px-4 py-2 rounded-md ${
                                            isActive ? "bg-gray-700" : "hover:bg-gray-700"
                                        }`
                                    }
                                >
                                    Preview Website
                                </NavLink>
                            </div>
                        )}
                    </div>

                    <NavLink
                        to="/logout"
                        onClick={() => logout()}
                        className={({ isActive }) =>
                            `block px-4 py-3 rounded-md ${
                                isActive ? "bg-gray-700" : "hover:bg-gray-700"
                            }`
                        }
                    >
                        <CiLogout className="inline-block mr-2" />
                        LogOut
                    </NavLink>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
