import React, {useState, useEffect, useRef, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar";
import {LoginContext} from "../../../Contexts/LoginContextProvider";
import {toast} from "react-toastify";

function EditVariant({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const { id: variantId } = useParams();
    const navigate = useNavigate();
    const [variant, setVariant] = useState({ name: "", order: 0, options: [] });
    const [newOption, setNewOption] = useState({
        name: "",
        image: "",
        price: "",
        stock: "",
    });

    const {loginState, getToken} = useContext(LoginContext);
    const token = getToken();

    const fetchVariant = () => {
        if (variantId !== "new") {
            fetch(`https://v2.whustle.com/variants/${variantId}`, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((data) => {
                    if(data.name && data.name.length > 0){
                        document.title = `${data.name} - Admin Panel`;
                    }
                    setVariant(data);
                })
                .catch((error) => console.error("Error fetching variant:", error));
        }
    }

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchVariant();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const handleSaveVariant = () => {
        const method = variantId === "new" ? "POST" : "PUT";
        const endpoint =
            variantId === "new"
                ? "https://v2.whustle.com/variants"
                : `https://v2.whustle.com/variants/${variantId}`;

        fetch(endpoint, {
            method,
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: variant.name, order: variant.order }),
        })
            .catch((error) => console.error("Error saving variant:", error));
        toast("Variant saved successfully.");
    };

    const handleAddOption = () => {
        fetch(`https://v2.whustle.com/variants/${variant.id}/options`, {
            method: "POST",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newOption),
        })
            .then((res) => res.json())
            .then((data) => {
                setVariant((prev) => ({
                    ...prev,
                    options: [...prev.options, { ...newOption, id: data.id }],
                }));
                setNewOption({ name: "", image: "", price: "", stock: "" });
            })
            .catch((error) => console.error("Error adding option:", error));
    };

    const handleDeleteOption = (optionId) => {
        fetch(`https://v2.whustle.com/variants/options/${optionId}`, {
            method: "DELETE",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                setVariant((prev) => ({
                    ...prev,
                    options: prev.options.filter((option) => option.id !== optionId),
                }));
            })
            .catch((error) => console.error("Error deleting option:", error));
    };

    return (
        <div className="bg-gray-900 text-gray-100 min-h-screen flex flex-col md:flex-row overflow-y-auto">
            <Navbar />
            <div className="p-4 md:p-8 h-screen overflow-y-auto mx-auto max-w-4xl">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-6">
                    {variantId === "new"
                        ? "Create New Variant"
                        : <>Edit Variant: <span className="text-blue-500">{variant.name}</span></>
                    }
                </h1>

                <div className="mb-6">
                    <label className="block text-gray-300 font-medium mb-2">
                        Variant Name
                    </label>
                    <input
                        type="text"
                        value={variant.name}
                        onChange={(e) =>
                            setVariant((prev) => ({ ...prev, name: e.target.value }))
                        }
                        className="w-full border border-gray-700 bg-gray-800 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="mb-6">
                    <label className="block text-gray-300 font-medium mb-2">
                        Order
                    </label>
                    <input
                        type="number"
                        value={variant.order}
                        onChange={(e) =>
                            setVariant((prev) => ({
                                ...prev,
                                order: Number(e.target.value),
                            }))
                        }
                        className="w-full border border-gray-700 bg-gray-800 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                {variantId !== "new" && (
                    <>
                        <h2 className="text-xl font-bold text-gray-100 mb-4">
                            Options
                        </h2>
                        <ul className="space-y-4">
                            {variant.options.map((option) => (
                                <li
                                    key={option.id}
                                    className="p-4 bg-gray-800 rounded-lg shadow flex justify-between items-center"
                                >
                                    <div>
                                        <p className="text-lg font-medium text-gray-100">
                                            {option.name}
                                        </p>
                                        <p className="text-sm text-gray-300">
                                            Price: {option.price || "N/A"}, Stock:{" "}
                                            {option.stock || "N/A"}
                                        </p>
                                    </div>
                                    <button
                                        onClick={() => handleDeleteOption(option.id)}
                                        className="text-white bg-red-600 hover:bg-red-700 font-medium rounded-lg px-4 py-2"
                                    >
                                        Delete
                                    </button>
                                </li>
                            ))}
                        </ul>

                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-gray-100 mb-4">
                                Add Option
                            </h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={newOption.name}
                                    onChange={(e) =>
                                        setNewOption((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))
                                    }
                                    className="border border-gray-700 bg-gray-800 text-gray-100 py-2 px-2 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                                <input
                                    type="text"
                                    placeholder="Image URL"
                                    value={newOption.image}
                                    onChange={(e) =>
                                        setNewOption((prev) => ({
                                            ...prev,
                                            image: e.target.value,
                                        }))
                                    }
                                    className="border border-gray-700 bg-gray-800 text-gray-100 py-2 px-2 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                                <input
                                    type="number"
                                    placeholder="Price"
                                    value={newOption.price}
                                    onChange={(e) =>
                                        setNewOption((prev) => ({
                                            ...prev,
                                            price: e.target.value,
                                        }))
                                    }
                                    className="border border-gray-700 bg-gray-800 text-gray-100 py-2 px-2 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                                <input
                                    type="number"
                                    placeholder="Stock"
                                    value={newOption.stock}
                                    onChange={(e) =>
                                        setNewOption((prev) => ({
                                            ...prev,
                                            stock: e.target.value,
                                        }))
                                    }
                                    className="border border-gray-700 bg-gray-800 text-gray-100 py-2 px-2 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <button
                                onClick={handleAddOption}
                                className="mt-4 text-white bg-green-600 hover:bg-green-700 font-medium rounded-lg px-6 py-3"
                            >
                                Add Option
                            </button>
                        </div>
                    </>
                )}

                <div className="mt-8">
                    <button
                        onClick={handleSaveVariant}
                        className="bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg px-6 py-3"
                    >
                        Save Variant
                    </button>
                    <button
                        onClick={() => navigate("/variantsList")}
                        className="ml-4 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-lg px-6 py-3"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditVariant;
