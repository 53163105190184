import React, {useState, useEffect, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar";
import {LoginContext} from "../../../Contexts/LoginContextProvider";

function EditReview({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const { id } = useParams();
    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: "",
        title: "",
        content: "",
        rating: "",
        verified: false,
        product_id: "",
        date: "",
        avatar_url: "",
        images: [],
    });
    const [newImage, setNewImage] = useState(null);
    const { loginState, getToken } = useContext(LoginContext);
    const token = getToken();

    const fetchReview = () => {
        fetch(`https://v2.whustle.com/review/${id}`, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((data) => setForm(data))
            .catch((error) => console.error("Error fetching review:", error));
    };

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchReview();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`https://v2.whustle.com/reviews/${id}`, {
            method: "PUT",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form),
        })
            .then(() => navigate("/reviews"))
            .catch((error) => console.error("Error updating review:", error));
    };

    const handleDeleteImage = (imageId) => {
        fetch(`https://v2.whustle.com/reviews/images/${imageId}`, {
            method: "DELETE",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then(() =>
                setForm((prev) => ({
                    ...prev,
                    images: prev.images.filter((img) => img.id !== imageId),
                }))
            )
            .catch((error) => console.error("Error deleting image:", error));
    };

    const handleAddImage = () => {
        if (!newImage) return;

        const formData = new FormData();
        formData.append("image", newImage);

        fetch(`https://v2.whustle.com/reviews/${id}/upload-image`, {
            method: "POST",
            body: formData,
            headers: {
                'Authorization': token,
            }
        })
            .then((res) => res.json())
            .then((data) => {
                setForm((prev) => ({
                    ...prev,
                    images: [...prev.images, { id: data.imageId, url: data.img_url }],
                }));
                setNewImage(null);
            })
            .catch((error) => console.error("Error adding image:", error));
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-gray-100 min-h-screen overflow-y-auto">
            <Navbar />
            <div className="p-4 md:p-8 mx-auto md:w-2/3 w-full">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-6">
                    Edit Review
                </h1>
                <form
                    onSubmit={handleSubmit}
                    className="bg-gray-800 rounded-lg shadow p-6 space-y-6"
                >
                    {/* Name */}
                    <div>
                        <label className="block text-gray-300 font-medium mb-2">
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    {/* Title */}
                    <div>
                        <label className="block text-gray-300 font-medium mb-2">
                            Title
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={form.title}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    {/* Content */}
                    <div>
                        <label className="block text-gray-300 font-medium mb-2">
                            Content
                        </label>
                        <textarea
                            name="content"
                            value={form.content}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            rows="4"
                        ></textarea>
                    </div>

                    {/* Rating */}
                    <div>
                        <label className="block text-gray-300 font-medium mb-2">
                            Rating
                        </label>
                        <input
                            type="number"
                            name="rating"
                            value={form.rating}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            min="1"
                            max="5"
                        />
                    </div>

                    {/* Verified */}
                    <div className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            name="verified"
                            checked={form.verified}
                            onChange={handleChange}
                            className="h-4 w-4 text-blue-600 border-gray-700 rounded"
                        />
                        <label className="text-gray-300 font-medium">
                            Verified
                        </label>
                    </div>

                    {/* Product ID */}
                    <div>
                        <label className="block text-gray-300 font-medium mb-2">
                            Product ID
                        </label>
                        <input
                            type="number"
                            name="product_id"
                            value={form.product_id}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    {/* Date */}
                    <div>
                        <label className="block text-gray-300 font-medium mb-2">
                            Date
                        </label>
                        <input
                            type="date"
                            name="date"
                            value={form.date}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    {/* Avatar URL */}
                    <div>
                        <label className="block text-gray-300 font-medium mb-2">
                            Avatar URL
                        </label>
                        <input
                            type="text"
                            name="avatar_url"
                            value={form.avatar_url}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    {/* Images Section */}
                    <div>
                        <h3 className="text-lg font-bold text-gray-100 mb-2">
                            Images
                        </h3>
                        <div className="flex gap-4 flex-wrap mb-4">
                            {form.images.map((img) => (
                                <div key={img.id} className="relative">
                                    <img
                                        src={img.url}
                                        alt="Review"
                                        className="w-20 h-20 object-cover rounded-lg"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => handleDeleteImage(img.id)}
                                        className="absolute top-0 right-0 bg-red-600 text-white text-sm rounded-full p-1"
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                        <input
                            type="file"
                            onChange={(e) => setNewImage(e.target.files[0])}
                            className="block w-full text-gray-300"
                        />
                        <button
                            type="button"
                            onClick={handleAddImage}
                            className="mt-2 bg-green-600 hover:bg-green-700 text-white font-medium rounded-lg px-4 py-2"
                        >
                            Add Image
                        </button>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg px-4 py-2 mt-6"
                    >
                        Update Review
                    </button>
                </form>
            </div>
        </div>
    );
}

export default EditReview;
