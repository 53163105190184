import React, {useContext, useEffect, useState} from 'react';
import Navbar from "../../Components/Navbar";
import {LoginContext} from "../../Contexts/LoginContextProvider";

function WebsitePreview ({title}) {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);


        document.title = `${title} - Admin Panel`;

        // Clean up (optional)
        return () => {
            document.title = "Admin Panel"; // Reset to default when the component unmounts
        };
    }, [title]); // Depend on title to update the title when the product changes

    const { loginState } = useContext(LoginContext);

    const [web, setWeb] = useState({});

    const loadData =  () => {
        setWeb(loginState.web);
    }

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn) {
            loadData();
        }
    }, [loginState.initialized, loginState.loggedIn]);

    return (<div className="flex  md:flex-row flex-col bg-black ">
            <Navbar/>
            <div className="p-8 overflow-y-auto h-screen w-full mx-auto bg-black">
                {loginState.initialized && <iframe
                    src={"https://" + web}
                    scrolling="no"
                    frameBorder="0"
                    loading="lazy"
                    className={`h-[1900px] w-full`}
                ></iframe>}
            </div>
        </div>
    );
};

export default WebsitePreview;
