import React, {useState, useEffect, useContext} from "react";
import Navbar from "../../Components/Navbar";
import {LoginContext} from "../../Contexts/LoginContextProvider";

function Finance({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const [balance, setBalance] = useState({});
    const [inTransit, setInTransit] = useState(0);
    const [pending, setPending] = useState(0);
    const [available, setAvailable] = useState(0);
    const [payouts, setPayouts] = useState([]);
    const [loading, setLoading] = useState(true);

    const {loginState, getToken} = useContext(LoginContext);
    const token = getToken();

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchData();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const fetchData = () => {
        setLoading(true);
        fetch(`https://v2.whustle.com/balance`, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setBalance(data);

                const availableTotal =
                    Array.isArray(data.available) ? data.available.reduce((sum, item) => sum + item.amount, 0) : 0;

                const pendingTotal =
                    Array.isArray(data.pending) ? data.pending.reduce((sum, item) => sum + item.amount, 0) : 0;

                const inTransitTotal = data.inTransit || 0;

                setPending(pendingTotal);
                setAvailable(availableTotal);
                setInTransit(inTransitTotal);
            })
            .catch((error) => console.error("Error fetching balance:", error))
            .finally(() => setLoading(false));
        fetch(`https://v2.whustle.com/payouts`, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPayouts(data);
            })
            .catch((error) => console.error("Error fetching payouts:", error))
            .finally(() => setLoading(false));
    };

    function calculateTotal(data) {
        const totalAmount =
            (Array.isArray(data.available) ? data.available.reduce((sum, item) => sum + item.amount, 0) : 0) +
            (Array.isArray(data.pending) ? data.pending.reduce((sum, item) => sum + item.amount, 0) : 0) +
            (data.inTransit || 0);  // Fallback to 0 if inTransit is undefined or null

        return totalAmount;
    }

    function formattedStatus(status) {
        switch (status) {
            case "pending":
                return "⏱ Pending";
            case "paid":
                return "✅ Paid";
            case "in_transit":
                return "💸 On the way";
            default:
                return status;
        }
    }

    return (
        <div className="flex md:flex-row flex-col w-full bg-gray-900 text-gray-100 min-h-screen overflow-y-auto">
            {loading && (
                <div
                    className="fixed flex justify-center items-center w-full h-screen bg-black bg-opacity-60 z-50 top-0 left-0">
                    <img
                        src="https://media.tenor.com/DYYUoqO0GJ0AAAAj/among-us-walking.gif"
                        alt="Loading"
                    />
                </div>
            )}
            <Navbar/>
            <div className="p-4 md:p-8 overflow-y-auto h-screen md:w-2/3 mx-auto">
                <div className={"flex-col"}>
                    <h2 className={"my-4 text-3xl font-bold"}>🏦 Balance</h2>
                    <div className={"my-8"}>
                        <h2 className={"my-4 mb-5 text-xl"}><span
                            className={"font-bold"}>Pending:</span> {!loading && pending} CZK</h2>
                        <h2 className={"my-4 mb-5 text-xl"}><span
                            className={"font-bold"}>In Transit:</span> {!loading && inTransit} CZK</h2>
                        <h2 className={"my-4 mb-5 text-xl"}><span
                            className={"font-bold"}>Total:</span> {!loading && calculateTotal(balance)} CZK</h2>
                    </div>
                </div>

                <h2 className={"my-4 text-3xl font-bold"}>💸 Payouts</h2>
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-800">
                    <table className="w-full text-sm text-left text-gray-200">
                        <thead className="text-xs uppercase bg-gray-700 text-gray-300">
                        <tr>
                            <th className="px-6 py-3">Created at</th>
                            <th className="px-6 py-3">Arrival Date</th>
                            <th className="px-6 py-3">Amount</th>
                            <th className="px-6 py-3">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {payouts.map((payout) => (
                            <tr
                                key={payout.id}
                                className="border-b border-gray-700 hover:bg-gray-700"
                            >
                                <td className="px-6 py-4">
                                    <span
                                        className="text-xs text-gray-400"> {new Date(payout.date).toLocaleString()}</span>
                                </td>
                                <td className="px-6 py-4">
                                    <span
                                        className="text-xs text-gray-400"> {new Date(payout.arrival_date).toLocaleString()}</span>
                                </td>
                                <td className="px-6 py-4">{payout.amount} CZK</td>
                                <td className="px-6 py-4">
                                    {formattedStatus(payout.status)}
                                </td>
                            </tr>
                        ))}
                        {payouts.length === 0 && (
                            <tr>
                                <td colSpan="5" className="px-6 py-4 text-gray-300 text-center">
                                    No payouts found.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Finance;
