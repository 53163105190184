import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Components/Navbar";
import { toast } from "react-toastify";
import { LoginContext } from "../../Contexts/LoginContextProvider";

function GenerateReviews({ title }) {
    const [form, setForm] = useState({
        productId: "",
        img_url: "",
        productDescription: "",
        word_count: 5,
    });

    const [products, setProducts] = useState([]);
    const [generatedReviews, setGeneratedReviews] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState(null);
    const { getToken, loginState } = useContext(LoginContext);
    const token = getToken();

    useEffect(() => {
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    useEffect(() => {
        // Fetch products for the dropdown
        const fetchProducts = async () => {
            try {
                const response = await fetch(`https://v2.whustle.com/products/${loginState.store_id}`, {
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch products");
                }

                const data = await response.json();
                setProducts(data);
            } catch (err) {
                toast.error("Failed to fetch products. Please try again.");
            }
        };

        if (loginState.initialized) {
            fetchProducts();
        }
    }, [loginState.loggedIn, loginState.storeId, token, loginState.initialized]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "productId") {
            const selectedProduct = products.find((product) => product.id === parseInt(value, 10));
            if (selectedProduct) {
                setForm((prev) => ({
                    ...prev,
                    [name]: value,
                    img_url: selectedProduct.img,
                }));
            } else {
                setForm((prev) => ({ ...prev, [name]: value }));
            }
        } else {
            setForm((prev) => ({ ...prev, [name]: value }));
        }
    };

    const generateReviews = async (e) => {
        e.preventDefault();
        setIsGenerating(true);
        setError(null);

        await toast.promise(
            (async () => {
                const response = await fetch("https://v2.whustle.com/generate-reviews", {
                    method: "POST",
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(form),
                });

                if (!response.ok) {
                    throw new Error("Failed to generate reviews");
                }

                const data = await response.json();
                setGeneratedReviews(data);
            })(),
            {
                pending: "Generating reviews...",
                success: "Reviews generated successfully!",
                error: "Failed to generate reviews. Please try again.",
            }
        );

        setIsGenerating(false);
    };

    const updateReview = (index, updatedReview) => {
        setGeneratedReviews((prev) =>
            prev.map((review, i) => (i === index ? updatedReview : review))
        );
    };

    const removeReview = (index) => {
        setGeneratedReviews((prev) => prev.filter((_, i) => i !== index));
        toast.info("Review removed.");
    };

    const handleSubmit = async () => {
        if (generatedReviews.length === 0) {
            toast.warn("No reviews to submit!");
            return;
        }

        toast.promise(
            fetch("https://v2.whustle.com/submit-reviews", {
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(generatedReviews),
            }),
            {
                pending: "Submitting reviews...",
                success: "Reviews submitted successfully!",
                error: "Failed to submit reviews. Please try again.",
            }
        );
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-gray-100 min-h-screen overflow-y-auto">
            <Navbar />
            <div className="p-4 md:p-8 h-screen overflow-y-auto mx-auto md:w-2/3 w-full">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-6">
                    Generate Reviews
                </h1>

                <form
                    onSubmit={generateReviews}
                    className="bg-gray-800 rounded-lg shadow p-6 space-y-6"
                >
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Product ID
                            </label>
                            <select
                                name="productId"
                                value={form.productId}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            >
                                <option value="">Select a Product</option>
                                {products.map((product) => (
                                    <option key={product.id} value={product.id}>
                                        {product.title} - {product.id}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Image URL
                            </label>
                            <input
                                type="url"
                                name="img_url"
                                value={form.img_url}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Description
                            </label>
                            <input
                                type="text"
                                name="productDescription"
                                value={form.productDescription}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Word Count
                            </label>
                            <input
                                type="number"
                                name="word_count"
                                value={form.word_count}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={isGenerating}
                        className={`w-full text-white font-medium rounded-lg px-4 py-2 ${
                            isGenerating ? "bg-gray-600 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
                        }`}
                    >
                        {isGenerating ? "Generating..." : "Generate Reviews"}
                    </button>
                </form>

                {error && (
                    <div className="text-red-400 mt-4">
                        <strong>Error:</strong> {error}
                    </div>
                )}

                {generatedReviews.length > 0 && (
                    <div className="mt-6">
                        <h2 className="text-xl md:text-2xl font-bold text-gray-100 mb-4">
                            Generated Reviews
                        </h2>
                        {generatedReviews.map((review, index) => (
                            <div
                                key={index}
                                className="bg-gray-800 p-4 rounded-lg shadow mb-4 space-y-2"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex-1 mr-4">
                                        <input
                                            type="text"
                                            value={review.title}
                                            onChange={(e) =>
                                                updateReview(index, {
                                                    ...review,
                                                    title: e.target.value,
                                                })
                                            }
                                            className="text-lg font-medium bg-gray-900 text-gray-100 w-full border border-gray-700 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        />
                                        <textarea
                                            value={review.content}
                                            onChange={(e) =>
                                                updateReview(index, {
                                                    ...review,
                                                    content: e.target.value,
                                                })
                                            }
                                            className="w-full mt-2 bg-gray-900 text-gray-100 border border-gray-700 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                            rows="2"
                                        />
                                    </div>
                                    <button
                                        onClick={() => removeReview(index)}
                                        className="text-red-400 hover:text-red-500 font-bold"
                                        title="Remove Review"
                                    >
                                        x
                                    </button>
                                </div>
                                <div className="text-gray-400 text-sm">
                                    Rating: {review.rating} | Date: {review.date}
                                </div>
                            </div>
                        ))}
                        <button
                            onClick={handleSubmit}
                            className="w-full mt-4 text-white bg-green-600 hover:bg-green-700 font-medium rounded-lg px-4 py-2"
                        >
                            Submit All Reviews
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GenerateReviews;
