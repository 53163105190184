import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../Contexts/LoginContextProvider";
import { PaginationContext } from "../../Contexts/PaginationContext";
import { toast } from "react-toastify";

function CDN({ title }) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const [images, setImages] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredImages, setFilteredImages] = useState([]);
    const imagesPerPage = 5;

    const { loginState } = useContext(LoginContext);
    const { savePaginationState, getPaginationState } = useContext(PaginationContext);

    // Restore pagination and search state on load
    useEffect(() => {
        const savedState = getPaginationState("cdn");
        setSearchTerm(savedState.filter || "");
        setCurrentPage(savedState.currentPage || 1);
    }, [getPaginationState]);

    // Fetch images from the API
    useEffect(() => {
        fetch("https://v2.whustle.com/imagesv2")
            .then((response) => response.json())
            .then((data) => {
                setImages(data.images);
                setFilteredImages(data.images);
            })
            .catch((error) => console.error("Error fetching images:", error));
    }, []);

    // Apply search term filtering
    useEffect(() => {
        const results = images.filter((image) =>
            image.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredImages(results);
        setCurrentPage(1); // Reset to the first page when the search term changes
    }, [searchTerm, images]);

    // Handle search input changes
    const handleSearchChange = (e) => {
        const newSearch = e.target.value;
        setSearchTerm(newSearch);
        savePaginationState("cdn", { currentPage: 1, filter: newSearch });
    };

    // Handle pagination changes
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        savePaginationState("cdn", { currentPage: newPage, filter: searchTerm });
    };

    // Pagination logic
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = filteredImages.slice(indexOfFirstImage, indexOfLastImage);
    const totalPages = Math.ceil(filteredImages.length / imagesPerPage);

    const copyToClipboard = (url) => {
        navigator.clipboard
            .writeText(url)
            .then(() => toast("URL copied to clipboard!"))
            .catch(() => toast("Failed to copy URL."));
    };

    return (
        <div className="flex md:flex-row flex-col bg-gray-900 text-gray-100 w-full min-h-screen overflow-y-auto">
            <div className="md:min-h-screen bg-gray-800">
                <Navbar />
            </div>

            <div className="w-full md:w-5/6 mx-auto p-4 md:p-8 bg-gray-900">
                <div className="p-4 md:p-8 min-h-screen md:w-2/3 mx-auto">
                    <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-100">Image Viewer</h1>

                    {/* Search Input */}
                    <div className="mb-6">
                        <input
                            type="text"
                            placeholder="Search images..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full px-4 py-2 border border-gray-700 rounded-lg bg-gray-800 text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300"
                        />
                    </div>

                    {/* Image Table */}
                    <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-800">
                        <table className="w-full text-sm text-left text-gray-200">
                            <thead className="text-xs uppercase bg-gray-700 text-gray-300">
                            <tr>
                                <th scope="col" className="px-6 py-3">Thumbnail</th>
                                <th scope="col" className="px-6 py-3">Image Name</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentImages.map((imageName, index) => {
                                const imageUrl = `https://cdn.whustle.com/${imageName}`;
                                return (
                                    <tr
                                        key={index}
                                        className="border-b border-gray-700 hover:bg-gray-700"
                                    >
                                        <td className="px-6 py-4">
                                            <img
                                                src={imageUrl}
                                                alt={imageName}
                                                className="w-16 h-16 object-cover rounded-lg"
                                            />
                                        </td>
                                        <td className="px-6 py-4 font-medium text-gray-100">
                                            {imageName}
                                        </td>
                                        <td className="px-6 py-4 space-y-2">
                                            <button
                                                onClick={() => window.open(imageUrl, "_blank")}
                                                className="text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2"
                                            >
                                                View
                                            </button>
                                            <button
                                                onClick={() => copyToClipboard(imageUrl)}
                                                className="ml-2 text-white bg-green-600 hover:bg-green-700 font-medium rounded-lg text-sm px-4 py-2"
                                            >
                                                Copy URL
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            {currentImages.length === 0 && (
                                <tr>
                                    <td colSpan="3" className="px-6 py-4 text-gray-300 text-center">
                                        No images found.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination Controls */}
                    {filteredImages.length > 0 && (
                        <div className="flex justify-between items-center mt-4">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                    currentPage === 1
                                        ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                        : "bg-blue-600 text-white hover:bg-blue-700"
                                }`}
                            >
                                Previous
                            </button>
                            <span className="text-gray-300 font-medium">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                    currentPage === totalPages
                                        ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                        : "bg-blue-600 text-white hover:bg-blue-700"
                                }`}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CDN;
